.App {
    text-align: center;
}
  
.App-logo {
    height: 40vmin;
    pointer-events: none;
}
  
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
}
  
.App-header {
    background-color: #1e2224;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

    .header {
        position: absolute;
        top: 50px;

        b {
            a {
                color: white;
                &:hover {
                    color: lightgray;
                }
            }
        }
        .description {
            margin-top: 10px;
            font-size: 18px;
        }

        .invite {
            font-size: 20px;
        }
    }

    .item {
        background: lighten($color: #1e2224, $amount: 10);
        width: 500px;
        margin-left: calc(50% - 250px);
        padding: 10px 20px 20px 20px;

        .title {
            font-weight: bold;
        }
        .description {
            margin-top: 10px;
            font-size: 14px;
        }
        .content {
            margin-top: 10px;
        }
    }
}

.button {
    display: inline-block;
    cursor: pointer;
    height: 50px;
    line-height: 45px;
    font-size: 22px;
    width: 50%;
}

.button-sub-text {
    background: #636e72;
    &:hover {
        background: #2d3436;
    }
}

.button-active {
    background: #6c5ce7;
    &:hover {
        background: #0984e3;
    }
}

.App-link {
    color: #61dafb;
}
  
@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}


.evidence-container {

}



p {
    text-align: center;
}
  
  